import {css, html, LitElement, TemplateResult} from 'lit';
import {msg, str} from '@lit/localize';
import {customElement, property, state} from "lit/decorators.js";
import {ThrilltechConfig} from "./interfaces/ThrillTechConfig";
import {ThrilltechError} from "./interfaces/ThrilltechError";
import {Tier} from "./interfaces/Tier";
import {ChannelClass, POST_MESSAGE_DRAWER} from "./constants/PostMessageConstants";

if (typeof require !== 'undefined') {
    require("./components/thrillpot-ticker");
    require("./components/opt-in-form");
    require("./components/opt-in-status");
    require("./components/terms-and-conditions");
    require("./components/thrilltech-content-card");
    require("./components/thrilltech-tickers");
    require("./components/error-display");
    require("./components/maintenance-display");
}

enum OPT_IN_PROGRESS {
    IN_PROGRESS,
    TNC,
    COMPLETE,
    EDIT_COMPLETE,
    ERROR,
    TICKERS,
    OPT_OUT_COMPLETE,
    MAINTENANCE,
    MAINTENANCE_END
}

@customElement('thrilltech-drawer')
export class ThrilltechDrawer extends LitElement {
    constructor() {
        super();
    }

    static override styles = [
        css`
            .thrilltech-drawer-wrapper {
                pointer-events: none;
                background-color: rgb(0 0 0 / 0);
                width: 100vw;
                height: 100vh;
                z-index: 18;
                position: fixed;
                left: 0;
                top: 0;
                transition: background-color 0.3s;
                font-family: var(--thrilltech-drawer-font-family);
            }
            .thrilltech-drawer-wrapper.thrilltech-drawer-open {
                pointer-events: all;
                background-color: var(--thrilltech-drawer-wrapper-bg-color);
            }
            .thrilltech-drawer {
                width: 100%;
                height: auto;
                bottom: 0;
                left: 0;
                z-index: 19;
                position: fixed;
                transition: transform 0.3s ease-out;
                transform: translateY(100%);
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                color: var(--thrilltech-drawer-text-color);
                max-height: 99%;
                overflow: hidden;
                line-height: normal;
            }

            .thrilltech-drawer-open .thrilltech-drawer {
                transform: translateY(0);
            }

            .thrilltech-drawer-header-left-section, .thrilltech-drawer-header-right-section {
                height: 25px;
                width: 25px;
                margin: 0 8px;
            }

            .thrilltech-drawer-close {
                width: 24px; 
                height: 24px;
                background: var(--thrilltech-drawer-close-icon);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }

            .thrilltech-drawer-header {
                height: 44px;
                background-color: var(--thrilltech-drawer-header-bg-color);
                font-family: var(--thrilltech-drawer-header-font-family);
                display: flex;
                position: sticky;
                box-shadow: var(--thrilltech-drawer-header-box-shadow);
                justify-content: space-between;
                align-items: center;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }

            .thrilltech-drawer-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 19.6px;
                margin: 0;
            }

            .thrilltech-drawer-content {
                background-color: var(--thrilltech-drawer-content-bg-color);
                max-height: calc(95vh - 44px);
                overflow: auto;
            }

            @media screen and (max-width: 1023px) and (orientation: landscape) {
                .thrilltech-drawer {
                    width: 375px;
                    margin-left: -187px;
                    left: 50%;
                }
            }

            @media screen and (min-width: 768px) and (max-width: 1023px) {
                .thrilltech-drawer {
                    width: 375px;
                    margin-left: -187px;
                    left: 50%;
                }
            }

            @media screen and (min-width: 1024px) {
                .thrilltech-drawer-wrapper {
                    display: none;
                    transition: none;
                }
                .thrilltech-drawer-open .thrilltech-drawer {
                    position: fixed;
                    bottom: unset;
                    top: 50%;
                    left: 50%;
                    border-radius: 16px;
                    width: 468px;
                    transition: none;
                    transform: translate(-50%, -50%);
                }

                .thrilltech-drawer-open {
                    display: block;
                }

                .thrilltech-drawer-header h4 {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 28px;
                }

                .thrilltech-drawer-close {
                    margin-left: 15px;
                }    

                .thrilltech-drawer-header {
                    height: 72px;
                }

                .thrilltech-drawer-content {
                    border-bottom-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                    max-height: calc(95vh - 72px);
                }
            }
        `
    ]

    @property({type: Boolean, attribute: 'drawer-open'})
    drawerOpen = false;

    @property({type: Boolean, attribute: 'player-opted-in'})
    playerOptedIn = false;

    @property({type: Boolean, attribute: 'user-in-game-page'})
    userInGamePage = false;

    @property({type: String, attribute: 'thrillpot-currency'})
    thrillpotCurrency = 'USD';

    @property({type: Number, attribute: 'site-id'})
    siteId =  1;

    @property({type: Object, attribute: "thrillpot-config"})
    thrillpotConfig: ThrilltechConfig = {
        sourceId: '',
        participation: {
            contributionValue: 0,
        },
        pots: [],
    }

    @property({type: Object, attribute: "thrilltech-error"})
    thrilltechError: ThrilltechError = {
        title: '',
        message: '',
        retry: false,
        tryReload: false,
    };

    @property({type: Boolean, attribute: 'maintenance'})
    maintenance = false;

    @state()
    progress:OPT_IN_PROGRESS = OPT_IN_PROGRESS.IN_PROGRESS;

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
        this.messageSubscription();

        if (this.thrillpotConfig.pots) {
            this.thrillpotConfig.pots.forEach(pot => pot.lastDropTime = 0);
        }

        if (this.thrilltechError.title !== '' || this.thrilltechError.message !== '') {
            this.progress = OPT_IN_PROGRESS.ERROR;
        } else if (this.maintenance) {
            this.progress = OPT_IN_PROGRESS.MAINTENANCE;
        }
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
        this.messageSubscription(false);
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
    }


    private messageSubscription(start = true) {
        window.postMessage({
            action: start ? 'subscribe' : 'unsubscribe',
            channel: ChannelClass.getWinChannel(this.siteId)
        }, window.location.origin);
        window.postMessage({
            action: start ? 'subscribe' : 'unsubscribe',
            channel: ChannelClass.getUpdateChannel(this.siteId, this.thrillpotConfig.sourceId?.toLowerCase())
        }, window.location.origin);
    }

    private getEnum(processNumber:Number) {
        switch (processNumber) {
            case 0:
                return OPT_IN_PROGRESS.IN_PROGRESS;
            case 1:
                return OPT_IN_PROGRESS.TNC;
            case 5:
                return OPT_IN_PROGRESS.TICKERS;
            default:
                return OPT_IN_PROGRESS.IN_PROGRESS;
        }
    }

    private postMessageHandler(event: MessageEvent) {
        if (window.location.origin !== event.origin) {
            return;
        }

        // drawerOpen
        if (event.data.action === POST_MESSAGE_DRAWER) {
            if (event.data.source_id?.toLowerCase() != this.thrillpotConfig.sourceId?.toLowerCase()){
                return;
            }

            if (this.progress !== OPT_IN_PROGRESS.MAINTENANCE && this.progress !== OPT_IN_PROGRESS.MAINTENANCE_END) {
                this.progress = this.getEnum(event.data.process);
            }

            this.drawerOpen = event.data.open ?? !this.drawerOpen;
            return;
        }

        if(!event.data.message){
            return
        }
        // thrillPots
        if (event.data.channel == ChannelClass.getWinChannel(this.siteId)) {
            let thrillpotData = event.data.message;

            if (typeof(thrillpotData) === 'string') {
                thrillpotData = JSON.parse(thrillpotData);
            }

            if (thrillpotData.source_id?.toLowerCase() != this.thrillpotConfig.sourceId?.toLowerCase()) {
                return;
            }
            const newPotArray: Tier[] = [];

            this.thrillpotConfig.pots.forEach(
                (currentPot) => {
                    if (thrillpotData.win_pot_id == currentPot.type) {
                        const mainWinnerAmount = parseInt(
                            thrillpotData.win_amounts.find(
                                amount => amount.currency === this.thrillpotCurrency,
                            )?.value,
                            10,
                        );
                        let totalWinAmount = parseInt(
                            thrillpotData.community_winners.map(communityWinner => {
                                if (communityWinner.currency === this.thrillpotCurrency) {
                                    return communityWinner.win_amount;
                                }
                                return 0;
                            }).reduce(
                                (sum, secondSum) => sum + secondSum, mainWinnerAmount,
                            ),
                            10,
                        );
                        if (Object.hasOwn(thrillpotData, 'total_amount_won')) {
                            totalWinAmount = parseInt(thrillpotData.total_amount_won[this.siteId === 209 ? 'JPY' : this.thrillpotCurrency], 10);
                        }
                        newPotArray.push({
                            ...currentPot,
                            lastDropValue: totalWinAmount,
                            lastWinners: [thrillpotData.player_name, ...thrillpotData.community_winners.map(winnerInfo => {return winnerInfo.player_name})],
                            lastDropTime: thrillpotData.timestamp
                        })
                    } else {
                        newPotArray.push(currentPot);
                    }
                }
            )

            this.thrillpotConfig = {
                ...this.thrillpotConfig,
                pots: newPotArray,
            };
        } else if (event.data.channel == ChannelClass.getUpdateChannel(this.siteId, this.thrillpotConfig.sourceId?.toLowerCase())) {
            let thrilltechData = event.data.message;

            if (typeof(thrilltechData) === 'string') {
                thrilltechData = JSON.parse(thrilltechData);
            }
            if (Object.hasOwn(thrilltechData, "maintenance")) {
                if (typeof thrilltechData.maintenance === 'boolean') {
                    this.updateMaintenanceMode(thrilltechData.maintenance);
                    console.log("Updated PlayJumbo maintenance mode to " + thrilltechData.maintenance);
                } else {
                    console.error("Invalid PlayJumbo maintenance mode type " + thrilltechData.maintenance);
                }
                return;
            }

            if (thrilltechData.pots.length > 0) {
                const newPotArray:Tier[] = [];
                thrilltechData.pots.forEach(
                    (newPot) => {
                        this.thrillpotConfig.pots.forEach(
                            (currentPot) => {
                                if (newPot.id == currentPot.type) {
                                    newPotArray.push({
                                        ...currentPot,
                                        currentValue: parseInt(newPot.current_value, 10),
                                    })
                                }
                            }
                        )
                    }
                );

                this.thrillpotConfig = {
                    ...this.thrillpotConfig,
                    pots: newPotArray,
                };
            }
        }
    }

    private updateMaintenanceMode(maintenanceMode: boolean) {
        this.maintenance = maintenanceMode;
        if (this.maintenance && this.progress !== OPT_IN_PROGRESS.MAINTENANCE) {
            this.progress = OPT_IN_PROGRESS.MAINTENANCE;
            if (!this.drawerOpen && this.userInGamePage && this.playerOptedIn) {
                this.sendDrawerOpenPostMessage();
                this.drawerOpen = true;
            }
        } else if (!this.maintenance && this.progress === OPT_IN_PROGRESS.MAINTENANCE) {
            if (this.userInGamePage) {
                this.progress = OPT_IN_PROGRESS.MAINTENANCE_END;
                this.drawerOpen = true;
                this.sendDrawerOpenPostMessage();
            } else if (this.drawerOpen) {
                this.progress = OPT_IN_PROGRESS.MAINTENANCE_END;
            } else {
                this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
            }
        }
    }

    private drawerOpenClass() {
        return this.drawerOpen ? 'thrilltech-drawer-open' : '';
    }

    private closeDrawer() {
        this.drawerOpen = false;
        if (
            [OPT_IN_PROGRESS.ERROR, OPT_IN_PROGRESS.COMPLETE, OPT_IN_PROGRESS.EDIT_COMPLETE, OPT_IN_PROGRESS.MAINTENANCE_END].includes(this.progress)
        ) {
            this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
        }
        window.postMessage({ action: 'THRILLTECH_DRAWER_CLOSED', source_id: this.thrillpotConfig.sourceId });
    }

    override render(): TemplateResult<1> {
        return html`
            <div class="thrilltech-drawer-wrapper ${this.drawerOpenClass()}">
                <div class="thrilltech-drawer">
                    <div class="thrilltech-drawer-header">
                        <div class="thrilltech-drawer-header-left-section">
                            <div @click="${this.closeDrawer}" class="thrilltech-drawer-close"></div>
                        </div>
                        <h4 class="thrilltech-drawer-title">${msg(str`PlayJumbo`)}</h4>
                        <div class="thrilltech-drawer-header-right-section"></div>
                    </div>
                    <div class="thrilltech-drawer-content">
                        ${this.renderBody()}
                    </div>
                </div>
            </div>`;
    }

    private renderBody() {
        switch (this.progress) {
            case OPT_IN_PROGRESS.COMPLETE:
                return this.renderOptInComplete("OPT_IN");
            case OPT_IN_PROGRESS.EDIT_COMPLETE:
                return this.renderOptInComplete("EDIT");
            case OPT_IN_PROGRESS.OPT_OUT_COMPLETE:
                return this.renderOptInComplete("OPT_OUT");
            case OPT_IN_PROGRESS.TNC:
                return this.renderTermsAndConditions();
            case OPT_IN_PROGRESS.ERROR:
                return this.renderErrorDisplay();
            case OPT_IN_PROGRESS.TICKERS:
                return this.renderThrillPots();
            case OPT_IN_PROGRESS.MAINTENANCE:
            case OPT_IN_PROGRESS.MAINTENANCE_END:
                return this.renderMaintenance();
            default:
                return this.renderOptInForm();
        }
    }

    private renderOptInComplete(status:string) {
        return html`
            <opt-in-status @opt-in-complete-close="${this.completeOptInClose}"
                           status='${status}'
                           site-id="${this.siteId}"
                           @cancel-opt-in="${this.cancelOptIn}"
                           @reload-drawer-handler="${this.reloadDrawerHandler}"
                           ?user-in-game-page="${this.userInGamePage}"></opt-in-status>
        `;
    }

    private renderOptInForm() {
        return html`
            <opt-in-form @successOptIn="${this.optInSuccessHandler}"
                         @successEditStake="${this.editStakeSuccessHandler}"
                         @successOptOut="${this.optOutSuccessHandler}"
                         @error="${this.optinErrorHandler}"
                         @tnc="${this.optInTncHandler}"
                         @cancel-opt-in="${this.cancelOptIn}"
                         bet-options="${this.getBetOptions()}"
                         current-bet-option="${this.thrillpotConfig.participation?.contributionValue}"
                         thrillpot-source="${this.thrillpotConfig.sourceId}"
                         ?player-opted-in="${this.playerOptedIn}"
                         site-id="${ this.siteId }"
                         ?user-in-game-page="${this.userInGamePage}"></opt-in-form>
        `;
    }

    private renderErrorDisplay() {
        return html`
            <error-display @error-reload="${this.reloadHandler}"
                           @error-retry="${this.retryHandler}"
                           @error-close="${this.errorCloseHandler}"
                           thrilltech-error="${JSON.stringify(this.thrilltechError)}"></error-display>
        `;
    }

    private renderMaintenance() {
        return html`
            <maintenance-display @maintenance-close="${this.maintenanceCloseHandler}"
                                 ?maintenance="${this.maintenance}"
                                 ?player-opted-in="${this.playerOptedIn}"></maintenance-display>
        `;
    }

    private renderThrillPots() {
        return html`
            <thrilltech-tickers ?user-in-game-page="${this.userInGamePage}"
                                ?player-opted-in="${this.playerOptedIn}"
                                site-id="${this.siteId}"
                                thrillpot="${JSON.stringify(this.thrillpotConfig)}"
                                thrillpot-currency="${this.thrillpotCurrency}"></thrilltech-tickers>
        `;
    }
    private renderTermsAndConditions() {
        return html`
            <terms-and-conditions site-id="${this.siteId}"
                                  @exit-tnc="${this.tncExitHandler}"></terms-and-conditions>
        `;
    }

    private getBetOptions() {
        if(this.thrillpotConfig?.sourceId?.toLowerCase() === 'sitewide_casino') {
            return "[0.10, 0.20, 0.40]";
        } else {
            return "[1.00, 2.00, 4.00]";
        }
    }

    private optInSuccessHandler(_event:CustomEvent) {
        this.playerOptedIn = true;
        this.thrillpotConfig.participation = {
            contributionValue: _event.detail.newBetOption,
        }
        this.progress = OPT_IN_PROGRESS.COMPLETE;
    }

    private optOutSuccessHandler(_event:Event) {
        this.playerOptedIn = false;
        this.thrillpotConfig.participation = undefined;
        this.progress = OPT_IN_PROGRESS.OPT_OUT_COMPLETE;
    }

    private editStakeSuccessHandler(_event:CustomEvent) {
        this.thrillpotConfig.participation = {
            contributionValue: _event.detail.newBetOption,
        }
        this.progress = OPT_IN_PROGRESS.EDIT_COMPLETE;
    }

    private optInTncHandler(_event:Event) {
        this.progress = OPT_IN_PROGRESS.TNC;
    }

    private tncExitHandler(_event:Event) {
        this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
    }

    private reloadDrawerHandler(_event:Event) {
        this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
    }

    private reloadHandler(_event:Event) {
        location.reload();
    }

    private retryHandler(_event:Event) {
        this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
    }

    private errorCloseHandler(_event:Event) {
        this.closeDrawer();
    }

    private completeOptInClose(_event:Event) {
        this.closeDrawer();
    }

    private cancelOptIn(_event:Event) {
        this.closeDrawer();
    }

    private maintenanceCloseHandler(_event:Event) {
        if (!this.maintenance) {
            this.progress = OPT_IN_PROGRESS.IN_PROGRESS;
        } else {
            this.closeDrawer();
        }
    }

    private optinErrorHandler(_event:CustomEvent) {
        this.thrilltechError.title = _event.detail.errorTitle;
        this.thrilltechError.message = _event.detail.errorMessage;
        this.thrilltechError.retry = _event.detail.retry;
        this.thrilltechError.tryReload = _event.detail.tryReload;
        this.progress = OPT_IN_PROGRESS.ERROR;
    }

    private sendDrawerOpenPostMessage() {
        window.postMessage({ action: 'THRILLTECH_DRAWER_OPENED' });
    }
}

if (!customElements.get('thrilltech-drawer')) {
    customElements.define('thrilltech-drawer', ThrilltechDrawer);
}
