import {css, html, LitElement, nothing, TemplateResult} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {msg, str} from '@lit/localize';
import {Tier} from "../interfaces/Tier";
import {asyncReplace} from 'lit/directives/async-replace.js';
import {ChannelClass} from "../constants/PostMessageConstants";

const HIGHEST_THRILLPOT_TIER = 'mega';

const POT_TYPE_NAMES = {
    mega: msg(str`Vera Jumbo`),
    major: msg(str`Jumbo`),
    minor: msg(str`Lucky Hour`),
}

const CASINO_MUST_DROP_BY_VALUES = {
    mega: 400000,
    major: 55000,
    minor: 2000,
}

const LIVE_MUST_DROP_BY_VALUES = {
    mega: 400000,
    major: 55000,
    minor: 5000,
}

const YUUGADO_POT_TYPE_NAMES = {
    mega: msg(str`Yuuga Jumbo`),
    major: msg(str`Jumbo`),
    minor: msg(str`Lucky Hour`),
}

const SHOW_WINNING_STATE_DELAY = 300000; // 5 minutes

let resizeTimer:setTimeout;

async function *nameCarousel(ticker, winners) {
    let currentOption = 0;
    while (currentOption < winners.length) {
        yield winners[currentOption];
        let element = ticker.renderRoot.querySelector('.thrillpot-winners-display');
        element.style.animation = null;
        element.offsetWidth;
        element.style.animation = "slide-through 3s ease";
        currentOption++;
        await new Promise((r) => setTimeout(r, 3000));
        if (currentOption >= winners.length) {
            currentOption = 0;
        }
    }
}

@customElement('thrillpot-ticker')
export class ThrillpotTicker extends LitElement {
    static override styles = [
        css`
            .thrillpot-display {
                display: flex;
                flex-direction: column;
                font-family: var(--thrilltech-tickers-font-family);
            }
            .thrillpot-display--header {
                text-align: justify;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                font-size: 12px;
                color: var(--thrilltech-thrillpot-display-header-color);
                text-transform: uppercase;
            }
            .thrillpot-display--header.large {
                font-size: 14px;
            }
            .thrillpot-ticker {
                display: flex;
                flex-direction: row-reverse;
                grid-gap: 1px;
            }

            .thrillpot-ticker > .thrillpot-inactive-digit-separator,
            .thrillpot-ticker > .thrillpot-inactive-digit,
            .thrillpot-ticker > .thrillpot-inactive-currency {
                font-weight: 700;
                font-size: var(--thrilltech-active-digit-small-font-size);
                margin-top: var(--thrilltech-inactive-digit-small-margin-top);
            }
            .thrillpot-ticker > .thrillpot-inactive-digit-separator.large,
            .thrillpot-ticker > .thrillpot-inactive-digit.large,
            .thrillpot-ticker > .thrillpot-inactive-currency.large {
                font-size: var(--thrilltech-inactive-digit-large-font-size);
            }

            .thrillpot-ticker > .thrillpot-emoji-left {
                margin-right: 4px;
            }

            .thrillpot-ticker > .thrillpot-emoji-right {
                margin-left: 4px;
            }

            .thrillpot-ticker > .thrillpot-active-digit-separator,
            .thrillpot-ticker > .thrillpot-active-digit,
            .thrillpot-ticker > .thrillpot-active-currency {
                display: flex;
                color: var(--thrilltech-active-digit-text-color);
                font-size: var(--thrilltech-active-digit-small-font-size);
                font-weight: 700;
                height: var(--thrilltech-active-digit-small-height);
                line-height: var(--thrilltech-active-digit-small-height);
            }

            .thrillpot-ticker > .thrillpot-active-digit.large,
            .thrillpot-ticker > .thrillpot-active-currency.large,
            .thrillpot-ticker > .thrillpot-active-digit-separator.large {
                color: var(--thrilltech-active-digit-text-color-large);
                height: var(--thrilltech-active-digit-large-height);
                line-height: var(--thrilltech-active-digit-large-height);
                font-size: var(--thrilltech-active-digit-large-font-size);
            }

            .thrillpot-ticker > .thrillpot-active-digit.large,
            .thrillpot-ticker > .thrillpot-active-currency.large {
                width: var(--thrilltech-active-digit-large-width);
            }

            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit-separator,
            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit,
            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-currency {
                font-size: 12px;
                line-height: var(--thrilltech-bar-active-digit-height);
                height: var(--thrilltech-bar-active-digit-height);
            }

            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit,
            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-currency {
                width: var(--thrilltech-bar-active-digit-width);
            }

            .thrillpot-ticker .thrillpot-active-digit.large {
                background-color: var(--thrilltech-active-digit-large);
            }

            .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-currency.large,
            .thrillpot-ticker .thrillpot-active-currency.large {
                background-color: var(--thrilltech-active-currency-large); 
            }

            .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-currency {
                background-color: var(--thrilltech-game-page-active-currency);
            }

            .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit-separator {
                color: var(--thrilltech-bar-ticker-active-digit-separator);
            }

            .thrillpot-ticker > .thrillpot-active-digit,
            .thrillpot-ticker > .thrillpot-active-currency {
                border-radius: 4px;
                width: var(--thrilltech-active-digit-small-width);
                justify-content: center;
            }

            .thrillpot-ticker > .thrillpot-active-digit {
                background-color: var(--thrilltech-active-digit-small); 
            }

            .thrillpot-ticker > .thrillpot-active-currency {
                background-color: var(--thrilltech-active-currency);
            }

            .thrillpot-ticker > .thrillpot-active-digit-separator {
                align-items: flex-end;
            }

            .thrillpot-tier-title {
                text-align: left;
                font-size: 10px;
                line-height: 26px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding: 0 5px 0 0;
            }

            .thrillpot-tier-dropped-value {
                font-size: 12px;
                font-weight: 700;
                color: var(--thrilltech-bar-ticker-dropped-color);
                margin-right: 2px;
            }
            .thrillpot-display.thrilltech-bar-ticker {
                flex-direction: row;
            }

            .thrillpot-must-drop {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                color: var(--thrilltech-must-drop-color);
            }

            .thrillpot-display.thrillpots-game-page > .thrillpot-must-drop {
                margin-top: 5px;
            }

            .thrillpot-display,
            .thrillpot-must-drop,
            .thrillpot-display--header {
                align-items: center;
                display: flex;
                flex-direction: column;
            }

            .thrillpot-results {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: var(--thrilltech-thrillpot-result);
                font-size: 12px;
            }

            .thrillpot-display.thrillpots-game-page .thrillpot-results.one-row {
                flex-direction: row !important;
                margin-left: -50px;
                gap: 3px;
            }

            .thrillpot-results--item {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .thrillpot-emoji {
                font-size: 13px;
            }
            .thrillpot-emoji.large {
                font-size: 20px;
            }
            .thrillpot-results--winners {
                color: var(--thrilltech-trillpot-winner);
            }
            .thrillpot-winner-animation-wrapper {
                position: absolute;
                overflow: hidden;
                display: inline;
                width: 60px;
                height: 14px;
            }
            .thrillpot-winners-display {
                position: absolute;
                margin-left: 3px;
            }
            @keyframes slide-through {
                0% {
                    opacity: 0;
                    bottom: -100%;
                }
                13% {
                    opacity: 1;
                    bottom: 5%;
                }
                18% {
                    bottom: -3%;
                }
                20% {
                    bottom: 0;
                }
                80% {
                    bottom: 0;
                }
                82% {
                    bottom: 3%;
                }
                87% {
                    bottom: -5%;
                    opacity: 1;
                }
                100% {
                    bottom: 100%;
                    opacity: 0;
                }
            }

            @media only screen and (max-width: 765px) {
                .thrillpot-display .thrillpot-must-drop{
                    align-items: center;
                }
                .thrillpot-display.thrillpot-content-card .thrillpot-results.one-row {
                    flex-direction: row !important;
                    margin-left: -50px;
                    gap: 3px;
                }
            }

            @media only screen and (min-width: 766px) {
                .thrillpot-results--item {
                    font-size: 12px;
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-ticker {
                    justify-content: flex-end !important;
                    
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-results {
                    align-items: flex-start !important;
                }

                .thrillpot-display.thrillpots-game-page .thrillpot-results.one-row {
                    flex-direction: row !important;
                    margin-left: -50px;
                    gap: 3px;
                }

                .thrillpot-display.thrillpot-content-card {
                    width: max-content;
                    align-items: start;
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit-separator,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-currency,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit.large,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-currency.large,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit-separator.large {
                    height: var(--thrilltech-content-card-active-digit-desktop-height);
                    font-size: var(--thrilltech-content-card-active-digit-desktop-font-size);
                    line-height: var(--thrilltech-content-card-active-digit-desktop-height);
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-inactive-digit.large,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-inactive-currency.large,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-inactive-digit-separator.large {
                    font-size: var(--thrilltech-content-card-inactive-digit-desktop-font-size);
                    margin-top: var(--thrilltech-inactive-digit-large-margin-top);
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-emoji.large {
                    font-size: 16px;
                    margin-top: 0;
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-currency,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-digit.large,
                .thrillpot-display.thrillpot-content-card .thrillpot-ticker > .thrillpot-active-currency.large {
                    width: var(--thrilltech-content-card-active-digit-desktop-width);
                }

                .thrillpot-display.thrillpot-content-card > .thrillpot-display--header {
                    font-size: 14px;
                }

                .thrillpot-display.thrillpot-content-card .thrillpot-results--winners {
                    margin-left: 0;
                }
                .thrillpot-display.thrillpot-content-card .thrillpot-winner-animation-wrapper {
                    width: 120px;
                    height: 17px;
                }
            }

            @media screen and (min-width: 1024px) { 
                .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit-separator,
                .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit,
                .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-currency {
                    font-size: 16px;
                    line-height: 30px;
                    height: 30px;
                }
    
                .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-digit,
                .thrillpot-display.thrilltech-bar-ticker .thrillpot-ticker > .thrillpot-active-currency {
                    width: 19px;
                }

                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-digit-separator,
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-digit,
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-currency {
                    display: flex;
                    color: var(--thrilltech-active-digit-text-color);
                    font-size: var(--thrilltech-game-page-active-digit-desktop-small-font-size);
                    font-weight: 700;
                    height: var(--thrilltech-game-page-active-digit-desktop-small-height);
                    padding-top: 2px;
                    line-height: var(--thrilltech-game-page-active-digit-desktop-small-height);
                }

                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-digit.large,
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-currency.large,
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-digit-separator.large {
                    color: var(--thrilltech-active-digit-text-color-large);
                    height: var(--thrilltech-game-page-active-digit-desktop-large-height);
                    line-height: var(--thrilltech-game-page-active-digit-desktop-large-height);
                    font-size: var(--thrilltech-game-page-active-digit-desktop-large-font-size);
                }
    
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-digit.large,
                .thrillpot-display.thrillpots-game-page .thrillpot-ticker > .thrillpot-active-currency.large {
                    width: var(--thrilltech-game-page-active-digit-desktop-width);
                }

                .thrillpot-tier-title {
                    font-size: 14px;
                    line-height: 32px;
                }

                .thrillpot-tier-dropped-value {
                    font-size: 14px; 
                    margin-right: 8px;
                }
            }
        `
    ]

    @property({type: String, attribute: 'thrillpot-source'})
    thrillpotSource = 'sitewide_casino';

    @property({type: String, attribute: 'thrillpot-currency'})
    thrillpotCurrency = 'USD';

    @property({type: Boolean, attribute: 'in-thrilltech-bar'})
    inThrilltechBar = false;

    @property({type: Boolean, attribute: 'user-in-game-page'})
    userInGamePage = false;

    @property({type: Number, attribute: 'site-id'})
    siteId = 1;

    @property({type: Object, attribute: 'thrillpot-info'})
    thrillpotInfo: Tier = {
        type: '',
        currentValue: 0,
        maximumValue: 0,
        lastDropValue: 0,
        lastWinners: [],
        lastDropTime: 0
    };

    @state()
    showTitle: boolean = false;

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();

        this.thrillpotInfo.lastDropTime = 0; // Don't show the dropped state on load, we do not have the correct won amount on load.
        this.setShowTitle();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    private isFeatureAvailable(): boolean {
        return this.thrillpotInfo.currentValue >= 0;
    }

    private CurrencySymbols: Record<string, string> = {
        'USD': '$',
        'JPY': '¥',
    }

    private bindEvents() {
        addEventListener('message', this.postMessageHandler.bind(this));
        addEventListener('resize', this.resizeHandler.bind(this));
    }

    private removeEvents() {
        removeEventListener('message', this.postMessageHandler.bind(this));
        removeEventListener('resize', this.resizeHandler.bind(this));
    }

    private resizeHandler(): void {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => this.setShowTitle(), 100);
    }

    private postMessageHandler(event: MessageEvent) {
        if (event.data.channel ==  ChannelClass.getWinChannel(this.siteId)) {
            let thrillpotData = event.data.message;

            if (typeof (thrillpotData) === 'string') {
                thrillpotData = JSON.parse(thrillpotData);
            }
            if (thrillpotData == undefined) {
                return;
            }

            if (thrillpotData.source_id?.toLowerCase() != this.thrillpotSource?.toLowerCase()) {
                return;
            }

            if (thrillpotData.win_pot_id?.toLowerCase().toString() == this.thrillpotInfo.type?.toLowerCase().toString()) {
                const mainWinnerAmount = parseInt(
                    thrillpotData.win_amounts.find(
                        amount => amount.currency === this.thrillpotCurrency,
                    )?.value,
                    10,
                );
                let totalWinAmount = parseInt(
                    thrillpotData.community_winners.map(communityWinner => {
                        if (communityWinner.currency === this.thrillpotCurrency) {
                            return communityWinner.win_amount;
                        }
                        return 0;
                    }).reduce(
                        (sum, secondSum) => sum + secondSum, mainWinnerAmount,
                    ),
                    10,
                );
                if (Object.hasOwn(thrillpotData, 'total_amount_won')) {
                    totalWinAmount = parseInt(thrillpotData.total_amount_won[this.siteId === 209 ? 'JPY' : this.thrillpotCurrency], 10);
                }
                this.thrillpotInfo = {
                    ...this.thrillpotInfo,
                    lastDropValue: totalWinAmount,
                    lastWinners: [thrillpotData.player_name, ...thrillpotData.community_winners.map(winnerInfo => {return winnerInfo.player_name})],
                    lastDropTime: thrillpotData.timestamp,
                }
            }
            this.requestUpdate();
        } else if (event.data.channel == ChannelClass.getUpdateChannel(this.siteId, this.thrillpotSource)) {
            let thrillpotData = event.data.message;

            if (typeof (thrillpotData) === 'string') {
                thrillpotData = JSON.parse(thrillpotData);
            }
            if (thrillpotData == undefined) {
                return;
            }
            if (Object.hasOwn(thrillpotData, "maintenance")) {
                return;
            }
            if (thrillpotData.allowed_sources?.indexOf(this.thrillpotSource) == -1) {
                return;
            }

            thrillpotData.pots.forEach(
                (pot) => {
                    if (pot.id == this.thrillpotInfo.type) {
                        this.thrillpotInfo.currentValue = parseInt(pot.current_value, 10)
                    }
                }
            );
            if (this.thrillpotIsActive()) {
                this.requestUpdate();
            }
        }
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        } else if (this.inThrilltechBar) {
            return html`
                <div class="thrillpot-display thrilltech-bar-ticker">
                    ${this.thrillpotIsActive() ? this.thrillpotTierTitle() : this.droppedMessage()}
                    ${this.getThrilltechBarThrillpot()}
                </div>
            `;
        }

        return html`
            <div class="thrillpot-display ${ this.userInGamePage ? 'thrillpots-game-page' : 'thrillpot-content-card' } ">
                ${this.getHeaderText()}
                ${this.getAmountDisplay()}
                ${this.thrillpotIsActive() ? this.getMustDropAmount() : this.getResultsDetails()}
            </div>
        `;
    }

    private thrillpotTierTitle(): TemplateResult<1> | undefined {
        if (!this.showTitle) {
            return;
        }
        
        return html`<div class="thrillpot-tier-title">${this.siteId === 209 ? msg(str`Yuuga Jumbo`) : msg(str`Vera Jumbo`)}</div>`;
    }

    private droppedMessage(): TemplateResult<1> {
        return html`<div class="thrillpot-tier-title">${msg(str`Just dropped!`)}</div>`;
    }

    private thrillpotIsActive() {
        if (!this.thrillpotInfo.lastDropTime || this.thrillpotInfo.lastDropValue == undefined){
            return true;
        }
        const lastDropTime = new Date(this.thrillpotInfo.lastDropTime);
        const now = new Date();

        return now.getTime() - SHOW_WINNING_STATE_DELAY > lastDropTime.getTime();
    }

    private getHeaderText(): TemplateResult<1> {
        return html`
            <div class="thrillpot-display--header ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">
                ${this.siteId === 209 ? YUUGADO_POT_TYPE_NAMES[this.thrillpotInfo.type as keyof typeof YUUGADO_POT_TYPE_NAMES] : POT_TYPE_NAMES[this.thrillpotInfo.type as keyof typeof POT_TYPE_NAMES]}
            </div>
        `;
    }

    private getThrilltechBarThrillpot(): TemplateResult<1> {
        if(this.thrillpotIsActive()) {
            return html`
                ${this.getAmountDisplay()}
            `;
        }
        return html`
            <div class="thrillpot-tier-dropped-value">${this.CurrencySymbols[this.siteId === 209 ? 'JPY' : this.thrillpotCurrency]}${this.thrillpotInfo.lastDropValue}</div>
        `;
    }

    private getAmountDisplay(): TemplateResult<1> {
        const isActive = this.thrillpotIsActive();
        return html`
            <div class="thrillpot-ticker ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">
                ${this.getEmoji("thrillpot-emoji-right")}
                ${String(isActive ? this.thrillpotInfo.currentValue : this.thrillpotInfo.lastDropValue).split("").reverse().map((digit, index) => {
                    return html`
                        ${(index) % 3 === 0 && index !== 0 ? html`<span class="thrillpot-${isActive ? 'active' : 'inactive'}-digit-separator ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">,</span>` : ''}
                        <span class="thrillpot-${isActive ? 'active' : 'inactive'}-digit ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">${digit}</span>
                    `;
                })}
                <span class="thrillpot-${isActive ? 'active' : 'inactive'}-currency ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">${this.CurrencySymbols[this.siteId === 209 ? 'JPY' : this.thrillpotCurrency]}</span>
                ${this.getEmoji("thrillpot-emoji-left")}
            </div>
        `;
    }

    private getEmoji(positionClass: String): TemplateResult<1> {
        if (this.thrillpotIsActive()) {
            return html``;
        }

        let emoji = '';
        if (this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER) {
            emoji =  '💵';
        } else {
            emoji = '🎉';
        }
        return html`
            <span class="thrillpot-emoji ${positionClass} ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'large' : ''}">${emoji}</span>
        `;
    }

    private getMustDropAmount(): TemplateResult<1> {
        return html`<div class="thrillpot-must-drop">${msg(str`Must drop by`)} ${this.CurrencySymbols[this.siteId === 209 ? 'JPY' : this.thrillpotCurrency]}${this.getMaximumValue()}</div>`;
    }

    private getMaximumValue(): string {
        if (this.thrillpotInfo.maximumValue != null) {
            return this.numberWithCommas('' + this.thrillpotInfo.maximumValue);
        }
        let value: number;
        if (this.thrillpotSource === "sitewide_live") {
            value = LIVE_MUST_DROP_BY_VALUES[this.thrillpotInfo.type as keyof typeof LIVE_MUST_DROP_BY_VALUES];
        } else {
            value = CASINO_MUST_DROP_BY_VALUES[this.thrillpotInfo.type as keyof typeof CASINO_MUST_DROP_BY_VALUES];
        }
        if (this.siteId === 209) {
            value = value * 100;
        }
        return this.numberWithCommas('' + value);
    }

    private numberWithCommas(x: string): string {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    private getResultsDetails(): TemplateResult<1> {
        return html`
            <div class="thrillpot-results ${this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER ? 'one-row' : ''}">
                <div class="thrillpot-results--item">${msg(str`Dropped`)}: ${this.formatDropTime(this.thrillpotInfo.lastDropTime)}</div>
                ${ this.thrillpotInfo.lastWinners.length > 0 ?
                    (this.thrillpotInfo.lastWinners.length === 1 ?
                        html`
                            <div class="thrillpot-results--item">
                                ${msg(str`Winner`)}: ${this.thrillpotInfo.lastWinners[0]}
                            </div>
                        ` :
                        html`
                            <div class="thrillpot-results--item thrillpot-results--winners">
                                ${msg(str`Winners`)}: 
                                <div class="thrillpot-winner-animation-wrapper">
                                    <span class="thrillpot-winners-display">
                                        ${asyncReplace(nameCarousel(this, this.thrillpotInfo.lastWinners))}
                                    </span>
                                </div>
                            </div>
                        `
                    ) : nothing }
            </div>
        `;
    }

    private formatDropTime(time: number): TemplateResult<1> {
        const dt: Date = new Date(time);

        if (this.thrillpotInfo.type === HIGHEST_THRILLPOT_TIER) {
            return html`
                ${dt.getFullYear() +"/"+ (dt.getMonth()+1) + "/" + dt.getDate() + ", " }
                ${this.zeroPadStart(dt.getHours())}:${this.zeroPadStart(dt.getMinutes())}${this.thrillpotInfo.lastWinners.length > 0 ? ", " : ""}
            `;
        }
        return html`${this.zeroPadStart(dt.getHours())}:${this.zeroPadStart(dt.getMinutes()) }  `;
    }

    private zeroPadStart(value: Number): String {
        return String(value).padStart(2, '0');
    }

    private setShowTitle(): void {
        this.showTitle = (!(this.thrillpotInfo.currentValue.toString().length > 6
            && window.innerWidth < 576))
    }
}

if (!customElements.get('thrillpot-ticker')) {
    customElements.define('thrillpot-ticker', ThrillpotTicker);
}
